import { graphql } from 'gatsby'
import { FlexCard, CardTextBlock } from '../Card'
import { getTranslatedMonth } from '../../utils'
import { useTranslation } from 'react-i18next'
import { Text as SharedText } from '@dfds-ui/typography'

const noDataString = 'No data available'

const Text = (props) => {
  const { monthAndYear, euroPerLmn, poundPerLmn } = props.routeItem
  const chargeDate = new Date(monthAndYear)
  const { t } = useTranslation()

  return (
    <SharedText styledAs={'interfaceBody'}>
      <b>
        {getTranslatedMonth(chargeDate.getMonth(), t)}{' '}
        {chargeDate.getFullYear()}
      </b>
      <br />
      {typeof euroPerLmn === 'number' ? `${euroPerLmn} €/Lmn` : noDataString}
      <br />
      {typeof poundPerLmn === 'number' ? `${poundPerLmn} £/Lmn` : noDataString}
    </SharedText>
  )
}

const BafChargeBlock = ({
  title,
  bafChargesList: { bafChargesCollection },
  bafChargeForRoute,
  ...rest
}) => {
  const [firstRoute, secondRoute] = bafChargesCollection.items.filter(
    ({ route }) => route === bafChargeForRoute,
  )

  return (
    <FlexCard {...rest}>
      <CardTextBlock title={title} titleTag={'h3'} />
      {firstRoute && secondRoute ? (
        <>
          <Text routeItem={firstRoute} />
          <Text routeItem={secondRoute} />
        </>
      ) : (
        <CardTextBlock text={noDataString} />
      )}
    </FlexCard>
  )
}

export const query = graphql`
  fragment BafChargeBlock on contentful_BafChargeBlock {
    bafChargeForRoute
    title
    bafChargesList {
      monthColumnOne
      monthColumnTwo
      title
      bafChargesCollection(limit: 40) {
        items {
          euroPerLmn
          explanationForMissingNumbers
          monthAndYear
          poundPerLmn
          route
        }
      }
    }
  }
`

export default BafChargeBlock
